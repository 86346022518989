<template>
  <div class="vtfx-account-autocomplete">
    <v-autocomplete
      v-model="myValue"
      :label="label"
      :items="itemsForSelect"
      :search-input.sync="searchInput"
      :disabled="disabled"
      :loading="loading"
      no-data-text="No matched value"
      item-text="value"
      item-value="value"
      return-object
      :hide-details="hideDetails"
      hide-selected
      :height="46"
      :rules="rules"
      :solo="solo"
      @change="$emit('change', myValue)"
    >
      <template v-slot:selection="data">
        <div style="padding: 0 16px;">
          <v-avatar color="blue lighten-1" size="32" style="margin-right: 20px;">
            <v-img v-if="data.item.icon" :src="data.item.icon"></v-img>
            <span v-else-if="data.item.name" class="white--text font-weight-bold">{{ data.item.name[0].toUpperCase() }}</span>
          </v-avatar>
          <span>{{ data.item.name }}</span>
        </div>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"></v-list-item-content>
        </template>
        <template v-else>
          <v-avatar color="blue lighten-1" size="32">
            <v-img v-if="data.item.icon" :src="data.item.icon" style="border-radius:50%"></v-img>
            <span v-else-if="data.item.name" class="white--text font-weight-bold">{{ data.item.name[0].toUpperCase() }}</span>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title
              style="height: 14px;line-height: 16px;"
            >
              {{ data.item.name }}
            </v-list-item-title>
            <v-list-item-title
              style="height: 14px;line-height: 16px;color: grey"
            >
              {{ data.item.email }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
      </template>
    </v-autocomplete>
    <msal-functions :ref="refMsalFunction"></msal-functions>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MsalFunctions from '@/components/msal/functions/index.vue'

export default {
  components: {
    MsalFunctions,
  },
  props: {
    value: {
      type: Object,
      default: () => ({
        email: null,
        name: null,
        icon: null
      })
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideDetails: {
      type: Boolean,
      default: true
    },
    solo: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => ([])
    },
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data () {
    return {
      default_icon: require('@/static/img/avatars/default.jpg'),
      loading: false,
      myValue: {
        value: '',
        email: '',
        name: '',
        icon: ''
      },
      searchInput: null,
      searchInputTime: null,
      items: [],
      refNo: Date.parse(new Date()),
    }
  },
  computed: {
    ...mapGetters('user', [
      'userEmail'
    ]),

    outValue () {
      return this.myValue ? {
          email: this.myValue.email || '',
          name: this.myValue.name || '',
          icon: this.myValue.icon || ''
        } : {
          email: '',
          name: '',
          icon: ''
        }
    },
    itemsForSelect () {
      let items = this.items.map(el => ({ ...el }))
      if (!!this.myValue && this.myValue.email && this.myValue.name && this.myValue.icon) {
        let index = items.findIndex(el => el.email === this.myValue.email && el.name === this.myValue.name && el.icon === this.myValue.icon)
        if (index === -1) {
          items.push({ ...this.myValue })
        }
      }
      return items
    },
    refMsalFunction () {
      return `msal-function-${this.refNo}`
    },
  },
  methods: {
    setMyValue (val) {
      try {
        if (!this.myValue || val.email !== this.myValue.email || val.name !== this.myValue.name || val.icon !== this.myValue.icon) {
          this.myValue = {
            value: (val.name + ';' + val.email) || '',
            email: val.email || '',
            name: val.name || '',
            icon: val.icon || ''
          }
          this.searchInput = val.email || ''
        }
      } catch (e) {}
    },
    async getItemsIcons (searchInput, searchInputTime) {
      // get icon
      for (let indexItems = 0; indexItems < this.items.length; indexItems++) {
        if (searchInput !== this.searchInput || searchInputTime !== this.searchInputTime) { return }
        const email = this.items[indexItems].email
        const icon = await this.$refs[this.refMsalFunction].getUserBase64Image({
          id: email,
          size: 48,
        })
        if (email === this.items[indexItems].email) {
          this.items[indexItems].icon = icon
        } else {
          return
        }
      }
    },
  },
  created () {
    this.setMyValue(this.value)
  },
  watch: {
    value: {
      deep: true,
      handler (val, oVal) {
        this.setMyValue(val)
      }
    },
    searchInput: {
      deep: true,
      async handler (val) {
        const searchInputTime = new Date()
        this.searchInputTime = searchInputTime
        let result = []
        let domain = this.userEmail.indexOf('nielsen.com') > 0 ? 'nielsen.com' : 'nielseniq.com'
        
        if (val && val.length > 2 && val === this.searchInput) {
          result = await this.$refs[this.refMsalFunction].getUserListByInput(val)
        }
        if (val === this.searchInput) {
          this.items = result
            // .filter((el, i) => i < 20)
            .map(el => ({
              value: el.displayName + ';' +el.mail.toLowerCase(),
              email: el.mail.toLowerCase(),
              name: el.displayName,
              icon: null
            }))
          // get icon
          this.getItemsIcons(val, searchInputTime)
        }
        this.loading = false
        if (this.myValue && this.myValue.name) this.items.push(this.myValue)
      }
    }
  }
}
</script>

<style lang="sass">
  .vtfx-account-autocomplete
    width: 100%
</style>
